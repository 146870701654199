import { createApp,h } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'

import { plugin } from 'echarts-for-vue';
import * as echarts from 'echarts';


const app = createApp(App)
      app.use(ElementPlus);
      app.use(router);
app.use(plugin, { echarts, h });
app.mount('#app')
/*router.beforeEach((to,from,next) => {

})*/


